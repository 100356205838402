<template>
  <div class="slider-wrap">
    <div class="slider-body" v-if="gameList.length > 0">
      <div class="game-menu">
        <div class="slider-item" :class="{ active: currentTab == index }" v-for="(item, index) in gameList" :key="index"
          @click="menuClick(index)">
          <!-- <img class="slider-img" :src="item[currentTab == index ? 'icon_after' : 'icon_before']" alt=""> -->
          <div class="icon-wrap">
            <img class="slider-img" v-if="currentTab === index" :src="icons[item.tag_code]" alt="">
            <Icon v-else :type="item.tag_code"></Icon>
          </div>
          <span class="title" v-autoSize="item.title">{{ item.title }}</span>
        </div>
      </div>
      <div class="music-player">
        <div class="music-Content">
          <div class="player-header">
            <div class="palyer-icon" @click="changePlayIndex(-1)">
              <Icon type="last" />
            </div>
            <div class="palyer-icon"  @click="playOrPause">
                <Icon :type="isPause ? 'play' : 'pause'" />
            </div>
            <div class="palyer-icon"  @click="changePlayIndex(1)">
              <Icon type="next" />
            </div>
            <div class="palyer-icon" @click="loopClick">
              <Icon class="loop-icon" :type="loopList[loopIndex].icon" />
            </div>
            <div class="palyer-icon" @click="Layout.$refs.musicDialog.show()">
              <Icon type="player" />
              <div class="music-count">{{ downloadIdArr.length }}</div>
            </div>
          </div>

          <div class="songName">{{ currentPlayMusicInfo.name }}</div>
        </div>
      </div>
      <div class="bet-record"  @click="toPage('/report?current=1')">
        <i>
          <Icon type="records" />
        </i>
        <span>{{ $t('Bet_Records') }}</span>
      </div>

      <div class="bet-record" style="margin-top: 0.1rem;"  @click="toPage('/agent')">
        <i>
          <Icon type="slider-agent" />
        </i>
        <span>{{ $t('tab_agent') }}</span>
      </div>
      <div class="activity-wrap">
        <div class="activity">
          <div class="offer-center">
            <span>{{ $t('Offer_Center') }}</span>
          </div>
          <div class="ac-item" @click="toPage('/offers?current=0')">
            <span>{{ $t('Event') }}</span>
            <img class="ac-img" src="@/assets/images/slider/btn_sy_zc_hd.png" alt="">
          </div>

          <div class="ac-item" @click="$emit('showMission')">
            <span>{{ $t('Mission') }}</span>
            <img src="@/assets/images/slider/btn_sy_zc_rw.png" alt="" class="ac-img">
          </div>

          <div class="ac-item" @click="toPage('/offers?current=3')">
            <span>Rebate</span>
            <img src="@/assets/images/slider/btn_sy_zc_fs.png" alt="" class="ac-img">
          </div>

          <div class="ac-item" @click="toPage('/offers?current=5')">
            <span>{{ $t('Claim') }}</span>
            <img src="@/assets/images/slider/btn_sy_zc_jl.png" alt="" class="ac-img">
          </div>
          <div class="ac-item" @click="toPage('/offers?current=6')">
            <span>{{ $t('History') }}</span>
            <img src="@/assets/images/slider/btn_sy_zc_lqjl.png" alt="" class="ac-img">
          </div>

          <div class="ac-item" @click="toPage('/offers?current=4')">
            <span>{{ $t('interest') }}</span>
            <img src="@/assets/images/slider/btn_sy_zc_yeb.png" alt="" class="ac-img">
          </div>

          <div class="ac-item" @click="toPage('/offers?current=2')">
            <span>VIP</span>
            <img src="@/assets/images/slider/btn_sy_zc_vip.png" alt="" class="ac-img">
          </div>

          <!-- <div class="ac-item full" @click="toPage('/agent')">

            <img class="ac-img" src="@/assets/images/slider/btn_sy_zc_tgzq.png" alt="">
            <span class="full-span">{{ $t('Agent') }}</span>
          </div> -->
        </div>

      </div>
      <div class="s-menu-list">
        <div class="sm-item">
          <div class="sm-left">
            <div class="sm-icon sm-signal">
              <div class="sm-link">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <span class="sm-delay">13MS</span>

            </div>
            <span class="sm-text">Servidor1</span>
          </div>

          <div class="sm-right">
            <Icon type="arrow-down" />
          </div>
        </div>
        <drop placement="right" ref="lang" @isShow="showLang = $event">

          <div class="sm-item" :class="{ active: showLang }">
            <div class="sm-left">
              <span class="sm-icon">
                <Icon type="lang" />
              </span>
              <span class="sm-text">{{ currentLangText }}</span>
            </div>
            <div class="sm-right">
              <Icon type="arrow-down" />
            </div>
          </div>
          <template slot="content">
            <div class="slider-right-wrap">
              <div class="slider-right">
                <div class="sr-item" :class="{ active: item.value == currentLang }" v-for="(item, index) in langList"
                  :key="index" @click="changeLang(item.value)">{{ item.label }}</div>
              </div>
            </div>
          </template>
        </drop>
        <div class="sm-item">
          <div class="sm-left">
            <div class="w-bg">
              <span class="sm-icon ">
                <Icon type="download" />
              </span>
            </div>
            <span class="sm-text">{{ $t('APP_download') }}</span>
          </div>
        </div>
        <div class="sm-item" @click="toPage('/customer?current=0')">
          <div class="sm-left">
            <div class="w-bg">
              <span class="sm-icon ">
                <Icon type="support" />
              </span>
            </div>
            <span class="sm-text">Suporte</span>
          </div>
        </div>
        <div class="sm-item" @click="toPage('/customer?current=0')">
          <div class="sm-left">
            <div class="w-bg">
              <span class="sm-icon ">
                <Icon type="fqa" />
              </span>
            </div>
            <span class="sm-text">FAQ</span>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-body" v-else>
      <div class="game-menu">
        <div class="loading-item loading-animation" v-for="item of 14" :key="item"></div>
        <div class="loading-item full loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item full loading-animation"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {

      icons: {
        amigos: require('@/assets/images/category/amigos.png'),
        jogos: require('@/assets/images/category/jogos.png'),
        popular: require('@/assets/images/category/popular.png'),
        slots: require('@/assets/images/category/slots.png'),
        blockchain: require('@/assets/images/category/blockchain.png'),
        pescaria: require('@/assets/images/category/pescaria.png'),
        cartas: require('@/assets/images/category/cartas.png'),
        cassino: require('@/assets/images/category/cassino.png'),
        esporte: require('@/assets/images/category/esporte.png'),
        clube: require('@/assets/images/category/clube.png'),
        esport: require('@/assets/images/category/esport.png'),
        luta: require('@/assets/images/category/luta.png'),
        loteria: require('@/assets/images/category/loteria.png')
      },
      langList: [
        { label: 'Português', value: 'por' },
        { label: 'Español', value: 'es' },
        { label: 'English', value: 'en' },
      ],
      currentLang: localStorage.getItem('language') || 'por',
      showLang: false,
    }
  },
  inject: ['Layout'],
  computed: {
    ...mapState({
      gameList: state => state.home.gameList,
      currentTab: state => state.home.currentTab,
      isPause: state => state.music.isPause,
      downloadIdArr: state => state.music.downloadIdArr,
      loopList: state => state.music.loopList,
      loopIndex: state => state.music.loopIndex,
      currentPlayMusicInfo: state => state.music.currentPlayMusicInfo,
    }),
    currentLangText() {
      return this.langList.filter(item => item.value === this.currentLang)[0]?.label
    }
  },
  methods: {
    loopClick() {
      let tempLoopIndex = this.loopIndex + 1;
      if (tempLoopIndex == 3) {
        tempLoopIndex = 0
      }
      console.log(tempLoopIndex)
      this.$store.commit('SET_LOOP_INDEX', tempLoopIndex);
    },
    playOrPause(){
      this.Layout.$refs.musicDialog.playOrPause();
    },
    changePlayIndex(step){
      this.Layout.$refs.musicDialog.changePlayIndex(step);
    },
    changeLang(value) {

      this.currentLang = value;
      localStorage.setItem('language', value)
      this.$i18n.locale = value;
      location.reload()
    },
    toPage(path) {
      this.$router.push(path)
      if (!this.Layout.isPc) {
        this.$store.commit('SET_SLIDER_SHOW', false)
      }
    },
    menuClick(index) {
      if (this.$route.path !== '/index') {
        this.$router.push('/index')
      }
      this.$store.commit('SET_CURRENT_TAB_ISSCROLL', false)
      this.$store.commit('SET_CURRENT_TAB', index)
    },
  },
}
</script>

<style lang='scss' scoped>
.slider-right-wrap {
  padding: 0 0.2rem;

  .slider-right {
    padding: .1rem 0;
    border-radius: 0.1rem;
    border: 0.01rem solid var(--theme-color-line);
    background-color: var(--theme-main-bg-color);
    animation: slider-from-left .3s;

    .sr-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--theme-text-color);
      font-size: .2rem;
      height: 0.55rem;
      line-height: .55rem;
      padding: 0 0.2rem;

      &:hover {
        color: var(--theme-primary-color);
        background-color: var(--theme-bg-color)
      }

      &.active {
        color: var(--theme-primary-color);
      }
    }
  }

}

.slider-wrap {
  height: 100%;
}

.slider-body {
  height: 100%;
  padding-bottom: 1rem;
  width: 3rem;
  box-sizing: border-box;
  background: var(--theme-main-bg-color);
  overflow-y: auto;

  .game-menu {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: .15rem .15rem 0;

    .loading-item {
      width: 1.3rem;
      height: 0.9rem;
      font-size: .2rem;
      margin-bottom: 0.1rem;
      border-radius: 0.14rem;

      &.full {
        width: 100%;
      }
    }

    .slider-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 0.1rem;
      border-radius: 0.14rem;
      cursor: pointer;
      height: 0.9rem;
      color: var(--theme-text-color);
      font-size: .2rem;
      width: 1.3rem;
      background: var(--theme-side-menu-btn-color);

      .title {
        text-align: center;
        font-size: 8.00427px !important;
        white-space: nowrap;
      }

      &.active {
        background: var(--theme-primary-color);
        color: var(--theme-primary-font-color);
      }

      .icon-wrap {
        width: 0.5rem;
        height: 0.38rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.26rem;
      }

      .slider-img {
        height: 0.38rem;
        object-fit: contain;
      }
    }
  }

  .music-player {
    background-color: var(--theme-side-menu-btn-color);
    border-radius: 0.1rem;
    height: .6rem;
    padding: 0.04rem 0.1rem;
    margin: 0 .15rem .1rem 0.15rem;
    position: relative;

    .music-Content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;
	  padding-top: 0.08rem;
		
      .player-header {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .palyer-icon {
          color: var(--theme-primary-color) !important;
          cursor: pointer;
          font-size: .14rem;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: inherit;
          display: inline-block;
          font-style: normal;
          line-height: 0;
          text-align: center;
          text-transform: none;
          position: relative;
          vertical-align: -0.125em;

          .music-count {
            background-color: var(--theme-main-bg-color);
            border: 0.01rem solid var(--theme-color-line);
            border-radius: 0.2rem;
            color: var(--theme-text-color);
            left: -0.32rem;
            line-height: .19rem;
            min-width: 0.2rem;
            padding: 0 0.06rem;
            position: absolute;
            text-align: center;
            top: -0.14rem;
          }
        }
      }

      .songName {
		margin-top: -.06rem;
        color: var(--theme-text-color);
        font-size: .18rem;
        left: 0;
        overflow: hidden;
        padding: 0 0.1rem;
        text-align: center;
        text-overflow: ellipsis;
        top: 0.3rem;
        white-space: nowrap;
        width: 90%;
      }
    }
  }

  .bet-record {
    font-size: .24rem;
    height: 0.75rem;
    align-items: center;
    display: flex;
    background-color: var(--theme-side-menu-btn-color);
    border-radius: 0.1rem;
    color: var(--theme-text-color);
    cursor: pointer;
    margin: 0 0.15rem;

    i {
      font-size: .36rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.35rem;
    }

    span {
      font-size: .24rem;
      width: 1.9rem;
      display: inline-block;
      text-align: center;
    }
  }

  .activity-wrap {
    width: 100%;
    box-sizing: border-box;
    padding: 0 .15rem;

    .activity {
      background-color: var(--theme-side-menu-btn-color);
      border-radius: 0.1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0.1rem 0 0.05rem;
      padding: 0.1rem 0.1rem 0;

      .offer-center{
        color: var(--theme-text-color-lighten);
        font-size: .24rem;
        padding-bottom: 0.1rem;
        text-align: center;
        width: 2.5rem;
      }
      .ac-item {
        cursor: pointer;
        margin-bottom: 0.1rem;
        position: relative;
        width: 48%;
        box-sizing: border-box;
        height: .8rem;
        border-radius: .1rem;
        font-size: .14rem;
        position: relative;

        span {
          position: absolute;
          left: .1rem;
          top: .1rem;
          color: #fff;
          right: .1rem;

          max-width: 0.7rem;
        }

        .ac-img {
          width: 100%;
          height: 100%;
        }

        &.full {
          width: 100%;
          display: flex;

          .full-span {
            font-size: .24rem;
            left: 33%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

  }

  .s-menu-list {
    padding: 0 .15rem;

    .sm-item {
      color: var(--theme-text-color-lighten);
      cursor: pointer;
      height: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.04rem 0 0.04rem 0.14rem;
      font-size: .22rem;
      position: relative;



      .sm-left {
        display: flex;
        align-items: center;

        .sm-signal {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 0.02rem;
        }

        .sm-icon {
          color: var(--theme-text-color-lighten);
          font-size: .32rem;
          margin-left: 0.01rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .sm-link {
            -ms-flex-align: end;
            align-items: flex-end;
            display: -ms-flexbox;
            display: flex;

            span:nth-of-type(1) {
              height: 0.1rem;
              margin-right: 0.04rem;
              border-radius: 0.023rem;
              display: block;
              width: 0.045rem;
              background: rgb(4, 190, 2);
            }

            span:nth-of-type(2) {
              height: 0.15rem;
              margin-right: 0.04rem;
              border-radius: 0.023rem;
              display: block;
              width: 0.045rem;
              background: rgb(4, 190, 2);
            }

            span:nth-of-type(3) {
              height: 0.2rem;
              margin-right: 0.04rem;
              border-radius: 0.023rem;
              display: block;
              width: 0.045rem;
              background: rgb(4, 190, 2);
            }
          }

          .sm-delay {
            font-size: .12rem;
            color: rgb(34, 141, 104);
          }
        }

        .w-bg {
          align-items: center;
          background: var(--theme-text-color-lighten);
          border-radius: 50%;
          display: flex;
          height: 0.32rem;
          width: 0.32rem;
          justify-content: center;
          position: relative;

          .sm-icon {
            color: var(--theme-side-menu-bg-color);
            font-size: .32rem;
            margin-left: 0.01rem;
          }
        }

        .sm-text {
          margin-top: -0.02rem;
          margin-left: 0.2rem;
        }
      }



      &:hover {
        color: var(--theme-primary-color);

        .sm-icon {
          color: var(--theme-primary-color);
        }

        .w-bg {
          background-color: var(--theme-primary-color);
        }

        .slider-right-wrap .slider-right {
          display: block;
          animation: slider-from-left .3s;
        }

      }

      &.active {
        color: var(--theme-primary-color);

        .sm-icon {
          color: var(--theme-primary-color);
        }

        .sm-right {
          margin-top: 0.05rem;
          transform: rotate(-90deg);
          color: var(--theme-primary-color);
        }
      }

      .sm-right {
        transition: all .3s;
        margin-top: 0.05rem;
      }
    }
  }
}


.pc-page {
  .slider-body {
    width: 2.4rem;

    .game-menu {
      .loading-item {
        width: 1rem;
        height: 0.7rem;
      }

      .slider-item {
        width: 1rem;
        height: 0.7rem;

        .title {
          text-align: center;
          font-size: 12px !important;
          white-space: nowrap;
        }

        .slider-img {
          width: 0.3rem;
          height: 0.38rem;
        }
      }
    }

    .bet-record span {
      font-size: .2rem;
    }

    .activity-wrap .activity .ac-item {
      height: 0.6rem;

      &.full {
        width: 100%;
        display: flex;

        .full-span {
          font-size: .18rem;
          left: 36%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

}
</style>