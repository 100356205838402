<template>
    <div class="mobile-el">
        <div id="tabbar">
            <div class="tabbar-item" v-for="(item, index) in tabList"
                :class="{ active: $route.path === (item.path?.includes('?') ? item.path.split('?')[0] : item.path) }"
                :key="index" @click="routeTo(item)">
                <i :class="['tabbar-icon', item.class]"></i>
                <span class="tabbar-text ">{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: 'Tabbar',
    inject: ['Layout'],
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo
        }),
        tabList() {
            const userInfo = this.userInfo
            const home = { name: this.$t('Home'), path: '/index', class: 'index' };
            const offer = { name: this.$t('offers'), path: '/offers?current=0', class: 'offers' };
            const register = { name: this.$t('register'), class: 'register' }
            const deposit = { name: this.$t('deposit'), class: 'deposit', login: true }
            const me = { name: this.$t('Profile'), path: '/me', class: 'me', login: true }
            const withdrawal = { name: this.$t('Agent'),  path: '/agent', class: 'set-password', login: true, type: 'push' }
            const service = { name: this.$t('Customer service'), path: '/customer', class: 'service', type: 'push' }
            let tabs = [];
            if (userInfo.id || localStorage.getItem('token')) {
                tabs = [home, offer, deposit, withdrawal, me]
            } else {
                tabs = [home, offer, register, service, me]
            }
            return tabs;
        }
    },
    methods: {
        routeTo(item) {
            if (item.login && !localStorage.getItem('token')) {
                this.Layout.$refs.loginPop.type = 'login'
                this.Layout.toRoute = item.path
                this.Layout.visible = true;
                return
            }
            const path = item.path?.includes('?') ? item.path.split('?')[0] : item.path
            if (path == undefined) {
                this.$emit('tabbar-click', item)
                return
            } else if (this.$route.path != path) {
                item.type === 'push' ? this.$router.push(item.path) : this.$router.replace(item.path)
            }
        }
    }
}
</script>
<style lang="scss">
#tabbar {
    box-shadow: 0.02rem 0 0.06rem 0 rgba(0, 0, 0, .1);
    background-image: linear-gradient(180deg, transparent, rgba(84, 84, 84, 0));
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 19;
    height: 1.24rem;
    display: flex;
    background: var(--theme-btm-bg-color);
    box-shadow: 0.02rem 0 0.06rem 0 rgba(0, 0, 0, .1);

    .tabbar-item {
        flex: 1;
        height: 100%;
        font-size: .2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--theme-btm-def-color);

        &.active {
            color: var(--theme-filter-active-color);

            .tabbar-icon {
                &.index {
                    background-position: -6.1rem -4.11429rem;
                    background-size: 7.42857rem 6.87143rem;
                }

                &.offers {
                    background-position: -3.6rem -5.95714rem;
                    background-size: 7.42857rem 6.87143rem;
                }

                &.me {
                    background-position: -1.54286rem -5.95714rem;
                    background-size: 7.42857rem 6.87143rem;
                }
            }


        }

        .tabbar-icon {
            display: inline-block;
            position: relative;
            width: 0.5rem;
            height: 0.5rem;
            background-image: url('@/assets/images/main.sprites.png');

            &.index {
                background-position: -6.1rem -3.6rem;
                background-size: 7.42857rem 6.87143rem;
            }

            &.offers {
                background-position: -3.08571rem -5.95714rem;
                background-size: 7.42857rem 6.87143rem;
            }

            &.register {
                background-position: -4.11429rem -5.95714rem;
                background-size: 7.42857rem 6.87143rem;
            }

            &.customer {
                background-position: -6.1rem -1.54286rem;
                background-size: 7.42857rem 6.87143rem;
            }

            &.me {
                background-position: -1.02857rem -5.95714rem;
                background-size: 7.42857rem 6.87143rem;
            }

            &.deposit {
                background-position: -4.50714rem -5.3rem;
                background-size: 7.42857rem 6.87143rem;
            }

            &.service {
                background-position: -6.1rem -1.54286rem;
                background-size: 7.42857rem 6.87143rem;
            }

            &.set-password {
                background-position: -6.1rem -4.64286rem;
                background-size: 7.42857rem 6.87143rem;
            }
        }



        .tabbar-text {
            height: 0.4rem;
            text-align: center;
        }
    }
}
</style>