import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en'    
import esLocale from './es'    
import porLocale from './por'
Vue.use(VueI18n)

 
const messages = {
  en: {
      ...enLocale,
  },
  por: {
      ...porLocale,
  },
  es:{
    ...esLocale
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem("language") ||'por',
  messages,
  silentFallbackWarn: true,     
})

export default i18n