import Vue from "vue";
import Router from "vue-router";
import layout from "@/layout";
import offers from "@/views/offers";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: layout,
    redirect: "/index",
    children: [
      {
        path: "index",
        name: "Index",
        meta: {
          keepAlive: true,
        },
        component: () => import("@/views/index"),
      },
      {
        path: "offers",
        name: "offers",
        meta: {
          keepAlive: true,
        },
        component: offers,
      },
      {
        path: "customer",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/customer/customer"),
      },
      {
        path: "search",
        meta: {
          keepAlive: true,
        },
        component: () => import("@/views/search"),
      },
      {
        path: "set-password",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/set-password/set-password"),
      },
      {
        path: "change-withdraw-password",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/changeWithdrawPassword"),
      },
      {
        path: "withdrawal",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/withdrawal/withdrawal"),
      },
      {
        path: "agent",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/agent/agent"),
      },
      {
        path: "game_list",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/gamelist"),
      },
      {
        path: "box",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/box/box"),
      },
      {
        path: "daily",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/daily/daily"),
      },
      {
        path: "turntable",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/turntable/turntable"),
      },
      {
        path: "cashback",
        meta: {
          keepAlive: false,
        },
        component:  () => import("@/views/cashback"),
      },
      {
        path: "privileges",
        meta: {
          keepAlive: false,
        },
        component:  () => import("@/views/privileges"),
      },
      {
        path: "loss",
        meta: {
          keepAlive: false,
        },
        component:  () => import("@/views/loss"),
      },
      {
        path: "lucky",
        meta: {
          keepAlive: false,
        },
        component:  () => import("@/views/lucky"),
      },
      {
        path: "first-deposit",
        meta: {
          keepAlive: true,
        },
        component:  () => import("@/views/first-deposit/first-deposit"),
      },
      {
        path: "me",
        meta: {
          login: true,
          keepAlive: true,
        },
        component:  () => import("@/views/me"),
      },
      {
        path: "report",
        meta: {
          login: true,
          keepAlive: true,
        },
        component:  () => import("@/views/report/report"),
      },
      {
        path: "wallet",
        meta: {
          login: true,
          keepAlive: true,
        },
        component:  () => import("@/views/wallet/wallet"),
      },
      {
        path: "game",
        meta: {
          login: true,
          keepAlive: false,
        },
        component:  () => import("@/views/game/game"),
      },
      {
        path: "profile",
        meta: {
          login: true,
          keepAlive: true,
        },
        component:  () => import("@/views/profile/profile"),
      },
      {
        path: "security",
        meta: {
          login: true,
          keepAlive: true,
        },
        component:  () => import("@/views/security/security"),
      },
      {
        path: "security-center",
        meta: {
          login: true,
          keepAlive: false,
        },
        component:  () => import("@/views/security-center/security-center"),
      },
      {
        path: "audit-details/:id",
        meta: {
          login: true,
          keepAlive: true,
        },
        component:  () => import("@/views/audit-details/audit-details"),
      },
      {
        path: "link-email",
        meta: {
          login: true,
          keepAlive: false,
        },
        component:  () => import("@/views/LinkEmail/index"),
      },
    ],
  },
];
const router = new Router({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
let changeUrl = "";
router.beforeEach((to, from, next) => {
  if (to.query.login && localStorage.getItem("token")) {
    next("/index");
    return;
  } else if (to.meta.login && !localStorage.getItem("token")) {
    next("/index?login=true");
    return;
  }else if(to.path =='/me' &&  document.getElementsByTagName('html')[0].dataset.deviceNodesktop ===1){
    next("/index");
  }
  next();
});

export default router;
