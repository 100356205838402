<template>
  <div class="pg-input-wrap">
    <div class='pg-input' ref="dropParent"  >
      <i class="input-prefix" v-if="prefix">
        <Icon :type="prefix"/>
      </i>

      <span class="input-prefix">
      <slot name="prefix"/>
    </span>
      <input :class="[{ bg }, { 'no-prefix': !$slots.prefix && !prefix }, { 'no-suffix': !$slots.suffix && !suffix }]"
             :value="value"
             :style="[{ height: (height || '0.66') + 'rem' }, { borderRadius: round ? '5rem' : '0.1rem' }]"
             :type="inputType" :placeholder="placeholder" @input="handleInput" :maxlength="maxlength"
             :disabled="disabled"
             @blur="hanlderBlur"
             @focus="handleFocus"
      >

      <span class="suffix-wrap">
      <i class="input-suffix eye" v-if="type == 'password' && showPassword && !isClose" @click="handlePassword">
        <Icon type="eye-close"/>
      </i>
      <i class="input-suffix eye-close" v-else-if="type == 'password' && showPassword && isClose"
         @click="handlePassword">
        <Icon type="eye"/>
      </i>
      <i class="input-suffix" v-else-if="suffix" @click="$emit('suffix')">
        <Icon :type="suffix"/>
      </i>
      <i class="input-suffix" v-if="clearable && value?.length && value.length > 0 && !disabled" @click="clear">
        <Icon type="close"/>
      </i>
      <span class="input-suffix">
        <slot name="suffix"/>
      </span>
    </span>
    </div>
    <pop placement="bottom-start">
      <ul class="pg-select-drop-list" v-if="filterOptions.length>0">
        <li class="drop-item" v-for="(item, index) in  filterOptions " :key="index"
            :class="[{ 'item-selected': value == item.value }, { bg }]" :style="{ minWidth: dropListWidth + 'px' }" @click="handleSelectItem(item)">
          <span >{{ item.label }}</span>
        </li>
      </ul>
      <div class="pg-select-drop-list" v-else>
        <div class="drop-item">{{ $t('No_Records') }}</div>
      </div>
    </pop>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter';
import pop from "@/components/drop/pop.vue";

export default {
  components: {pop},
  mixins: [Emitter],
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    prefix: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    suffix: {
      type: String,
    },
    maxlength: {
      type: [String, Number],
    },
    height: {
      type: String,
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    prefixSelect: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bg: {
      type: Boolean,
      default: false
    },
    inputSelect: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    filterOptions:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {
      inputType: 'text',
      isClose: false,
      visible:false,
      dropListWidth: 0,
    }
  },
  watch: {
    type: {
      handler(val) {
        this.inputType = val
      },
      immediate: true
    },
    visible(val) {
      this.broadcast('dropItem', 'visible', [
        val,
      ]);
    },
  },
  updated(){
    this.$nextTick(() => {
      this.dropListWidth = this.$refs.dropParent.offsetWidth
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.dropListWidth = this.$refs.dropParent.offsetWidth
    })
  },
  methods: {
    handleFocus(){
      if(this.inputSelect){
        this.visible = Boolean(this.value != '');
      }
    },
    handleSelectItem(item){
      this.$emit('input', item.value)
    },
    handleInput(e) {
      const  value = e.target.value;
      if (this.type == 'number') {
        e.target.value = value.replace(/[^\d]/g, '')
        if (this.maxlength) {
          e.target.value = value.slice(0, this.maxlength)
        }
      }
      if(this.inputSelect){
        this.visible = value!='';
      }
      this.$emit('input', value)
    },
    hanlderBlur(e) {
      if (this.min !== undefined && e.target.value < this.min) {
        e.target.value = this.min
      }
      if (this.max !== undefined && e.target.value > this.max) {
        e.target.value = this.max
      }
      if(this.inputSelect){
        setTimeout(()=>{
          this.visible = false
        })
      }
      this.$emit('blur')
      this.$emit('input', e.target.value)
    },
    clear() {
      this.$emit('input', '')
      this.$emit('clear')
    },
    handlePassword() {
      this.isClose = !this.isClose
      this.inputType = this.isClose ? 'text' : 'password'
    }
  },
}
</script>

<style lang='scss' scoped>
.pg-input {
  font-size: .22rem;
  border-radius: 0.06rem;
  position: relative;

  .input-prefix {
    position: absolute;
    top: 50%;
    left: 0;
    width: .6rem;
    color: inherit;
    font-size: .3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-text-color);
    transform: translateY(-50%);

    &.anticon {
      height: 0.25rem;
      width: 0.25rem;
    }
  }

  .suffix-wrap {
    .input-suffix {
      position: absolute;
      top: 50%;
      color: var(--theme-text-color);
      transform: translateY(-50%);
      right: 0.2rem;
      display: flex;
      align-items: center;
      font-size: 0.36rem;

      &.eye-close {
        color: #fff;
      }

      &:nth-child(2) {
        right: 0.75rem;
      }
    }

  }

  input {
    background: transparent;
    color: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 0 .6rem;
    border: none;
    outline: none;
    border: 1px solid var(--theme-color-line);
    transition: all .3s;

    &.no-prefix {
      padding-left: 0.22rem;
    }

    &.no-suffix {
      padding-right: 0.22rem;
    }

    &:focus {
      border-color: var(--theme-primary-color);;
    }

    &.bg {
      background: var(--theme-main-bg-color);
    }
  }
}




  .pg-select-drop-list {
    max-height: 5.1rem;
    padding: 0.14rem 0;
    overflow-y: auto;
    border-radius: 0.14rem;
    box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, .1);
    background-color: var(--theme-main-bg-color);
    border: 0.01rem solid var(--theme-color-line);
    transform: translateY(5px);

    .drop-item {
      font-size: .24rem;
      user-select: none;
      line-height: .8rem;
      min-height: 0.8rem;
      box-sizing: border-box;
      padding: 0 0.2rem;
      background-color: var(--theme-main-bg-color);
      color: var(--theme-text-color-lighten);
      cursor: pointer;

      &:hover{
        background-color: var(--theme-bg-color)
      };

      &.item-selected {
        color: var(--theme-primary-color);
      }

      &.item-selected.bg {
        background-color: var(--theme-bg-color);
      }
    }

    div.drop-item {
      cursor: default;
    }
  }


.pc-page {
  .pg-select-drop-list {
    .drop-item {
      font-size: .2rem;
      line-height: .55rem;
      height: 0.55rem;
      min-height: 0.55rem;
    }
  }
}
</style>