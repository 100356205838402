<template>
  <transition name="pg-drawer">
    <div class='deposit' v-show="visible" @click.self="handlerClose">
      <div class="deposit-body">
        <div class="des-header">
          <span @click="handlerClose">
            <Icon class="arrow-left mobile-el" type="arrow-line-left" />
          </span>
          <div>{{ $t('deposit') }}</div>
          <div class="record">
            <span @click="Layout.depositRecordVisible = true">{{ $t('Deposit_Records') }}</span>
            <div class="pc-el">
              <span class="close-line" @click="handlerClose">
                <Icon type="close-line" />
              </span>
            </div>
          </div>
        </div>
        <div class="de-main" v-loading="loading">
          <div class="de-tabs">
            <div class="tab-item">
              <i class="online">
                <Icon type="online" />
              </i>
              <span>{{ $t('Online_Deposit') }}</span>
            </div>
          </div>
          <div class="option-row">
            <div class="option-item" :class="{ active: currentPayment === index }" v-for="(item, index) in paymentList"
              :key="index" @click="currentPayment = index">
              <img class="option-img" src="@/assets/images/pix.png" alt="">
              <span class="option-text">{{ item.pay_way }}</span>
              <div class="icon-true-wrap">
                <i class="icon-true">
                  <Icon type="true" />
                </i>
                <Icon type="true-bg" class="true-bg" />
              </div>
            </div>
          </div>
          <div class="option-row" v-if="paymentList.length > 0">
            <div class="option-item" v-for="(item, index) in paymentList[currentPayment].payment_list" :key="index"
              :class="{ active: index === current }" @click="current = index">

              <span class="option-text">{{ item.name }}</span>
              <div class="icon-true-wrap">
                <i class="icon-true">

                  <Icon type="true" />
                </i>
                <Icon type="true-bg" class="true-bg" />
              </div>
            </div>
          </div>
          <div class="amount-wrap">
            <div class="amount-header">
              <div class="">{{ $t('Deposit_Amount') }}</div>
              <div class="t-active">{{ $t('Bonus_event_explanation') }}</div>
            </div>
            <div class="option-row price" v-if="amountList.length > 0">
              <div class="option-item " v-for="(item, index) in amountList" :key="index"
                :class="{ active: item.amount === currentPrice }" @click="currentPrice = item.amount">

                <span class="option-text">{{ item.amount }}</span>
                <div class="icon-true-wrap">
                  <i class="icon-true">

                    <Icon type="true" />
                  </i>
                  <Icon type="true-bg" class="true-bg" />
                </div>
                <div class="cur-tag" v-if="item.money > 0">
                  <div class="tips-box">+R${{ item.money }}</div>
                </div>
                <div class="cur-tag" v-else-if="item.reward > 0">
                  <div class="tips-box">+{{ item.reward }}%</div>
                </div>
              </div>
            </div>
            <Pg-input v-model="price">
              <i slot="prefix">R$</i>
            </PG-input>
            
            <div class="btn">
              <!-- <div class="tip-box" v-if="is_first">
                <div class="tip-content">{{ $t('The_first_deposit_to_receive') }} 3,00</div>
                <div class="tip-point"></div>
              </div> -->
              <Pg-button class="de-btn" type="primary" :loading="btnLoading" @click="deposit">{{ $t('Deposit_Now')
              }}</Pg-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { apiGetPaymentsList, apiPostOnline } from '@/api'
import { isiOS } from '@/util'
export default {
  data() {
    return {
      current: 0,
      list: [],
      currentPrice: 20,
      currentPayment: 0,
      is_first: 0,
      paymentList: [],
      loading: false,
      price: '',
      priceList: [],
      btnLoading: false,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    amountList() {
      return this.paymentList[this.currentPayment]?.payment_list[this.current]?.amount_list || []
    }

  },
  inject: ['Layout'],
  watch: {
    visible(val) {
      if (val) {
        this.getPayList();
      }
    }
  },
  methods: {
    getPayList() {
      this.loading = true;
      apiGetPaymentsList().then(res => {
        this.paymentList = res.data
        this.is_first = res.is_first
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    },
    handlerClose() {
      this.$emit('update:visible', false)
    },
    deposit() {
      const query = {
        payment_id: this.paymentList[this.currentPayment].payment_list[this.current].id,
        money: this.price || this.currentPrice
      }
      this.btnLoading = true;
      var windowpop = window.open('about:blank', '_blank', 'width=' + window.screen.width + ', height=' + window.screen.height + ',  ...')

      apiPostOnline(query).then(res => {
        if (res.code == 200) {
          windowpop.location = res.pay_url;
        }
      }).finally(() => {
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.deposit {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: .6);
  z-index: 100;

  .deposit-body {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 93vh;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.2rem 0.2rem 0 0;
    animation: slide-form-bottm .3s;

    .des-header {
      position: relative;
      display: flex;
      align-items: center;
      color: var(--theme-text-color-darken);
      font-size: .3rem;
      padding: .2rem .3rem 0 .3rem;
      justify-content: center;

      .arrow-left {
        color: var(--theme-text-color-darken);
        font-size: .24rem;
        left: 0.24rem;
        position: absolute;
        top: 0.24rem;
      }

      .record {
        position: absolute;
        font-size: .24rem;
        right: 0.3rem;
        color: var(--theme-primary-color);
        cursor: pointer;
        display: flex;
        align-items: center;

        .close-line {
          display: inline-block;
          color: #fff;
          font-size: .16rem;
          height: 0.56rem;
          line-height: .56rem;
          padding: 0 0.1rem;
          font-size: .16rem;
          margin-left: .16rem;
        }
      }
    }

    .de-main {
      padding: 0 0.3rem;
    }

    .de-tabs {
      height: .72rem;
      line-height: .72rem;
      font-size: 0;
      border-bottom: 1px solid var(--theme-color-line);

      .tab-item {
        display: inline-block;
        color: var(--theme-primary-color);
        font-size: 0.24rem;
        border-bottom: 0.04rem solid var(--theme-primary-color);

        .online {
          margin-right: 0.1rem;
        }
      }
    }

    .option-row {
      padding: 0.2rem 0;
      display: flex;
      flex-wrap: wrap;
      border-bottom: thin solid var(--theme-color-line);

      &.price {
        padding-bottom: 0;
        border-bottom: 0;

        .option-item {
          width: 22.375%;
          margin-bottom: .2rem;
        }
      }

      .option-item {
        border: 1px solid var(--theme-color-line);
        border-radius: 0.1rem;
        font-size: .22rem;
        color: var(--theme-color-line);
        align-items: center;
        display: flex;
        align-items: center;
        background-color: var(--theme-main-bg-color);
        border-radius: 0.1rem;
        color: var(--theme-text-color);
        cursor: pointer;
        font-size: .18rem;
        margin: 0 3.5% 0 0;
        padding: 0 0.1rem;
        box-sizing: border-box;
        height: 0.6rem;
        position: relative;
        width: 31%;

        .cur-tag {
          height: 0.32rem;
          left: unset;
          position: absolute;
          right: -0.1rem;
          top: -0.18rem;
          width: fit-content;
          z-index: 100;

          .tips-box {
            background-color: var(--theme-secondary-color-error);
            border-radius: 0.125rem 0.125rem 0.125rem 0;
            color: #fff;
            width: 100%;
            padding: 0 .04rem;
            font-size: .18rem;
            height: 0.26rem;
          }
        }


        &:nth-child(4n) {
          margin-right: 0;
        }

        .option-img {
          width: 0.44rem;
        }

        .option-text {
          flex: 1;
          text-align: center;
        }

        .icon-true-wrap {
          display: none;
          height: 0.3rem;
          line-height: .3rem;
          overflow: hidden;
          position: absolute;
          right: -0.02rem;
          width: 0.3rem;
          bottom: 0;

          .icon-true {
            position: absolute;
            right: 0.02rem;
            font-size: 0.15rem;
            bottom: -0.07rem;
            color: var(--theme-web_filter_gou);
            position: absolute;
            z-index: 1;
          }

          .true-bg {
            position: absolute;
            right: 1px;
            font-size: 0.3rem;
            bottom: -1px;
          }
        }

        &.active {
          color: var(--theme-primary-color);
          border: 1px solid var(--theme-filter-active-color);

          .icon-true-wrap {
            display: block;
          }
        }
      }

    }

    .amount-wrap {
      padding: .3rem 0;

      .amount-header {
        display: flex;
        align-items: baseline;
        color: var(--theme-text-color-darken);
        font-size: .18rem;
        justify-content: space-between;

        .t-active {
          color: var(--theme-primary-color);
          cursor: pointer;
          font-size: .24rem;
        }
      }

      ::v-deep .pg-input {
        .input-prefix {
          color: var(--theme-primary-color);

        }

        input {
          padding-left: 0.8rem;
          font-size: .24rem;
        }
      }

      .de-text{
        color: var(--theme-text-color-darken);
      display: -ms-flexbox;
      display: flex;
      font-size: .18rem;
      margin-bottom: 0.2rem;
      word-break: break-all;
      }
      .btn {
        margin-top: 0.3rem;
        font-size: 0;
        position: relative;
        text-align: center;

        .tip-box {
          padding: 0 0.3rem;
          height: 0.32rem;
          left: unset;
          position: absolute;
          right: 0;
          top: -0.18rem;

          .tip-content {
            border-radius: 0.14rem;
            font-size: .24rem;
            height: 0.7rem;
            width: 100%;
            font-size: .18rem;
            background-color: var(--theme-secondary-color-error);
            border-radius: 0.125rem 0.125rem 0.125rem 0;
            color: #fff;
            height: 0.26rem;
            line-height: .26rem;
            padding: 0 0.07rem;
            color: rgb(255, 255, 0);
          }

          .tip-point {
            border-bottom: 0.07rem solid transparent;
            border-left: 0.07rem solid var(--theme-secondary-color-error);
            border-right: 0.07rem solid transparent;
            height: 0;
            width: 0;
          }
        }
      }
    }
  }
}

.mobile-page{
  .de-btn {
    width: 100%;
  }
}

.pc-page {
  .deposit {
    .deposit-body {
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: auto;
      transform: translate(-50%, -50%);
      animation: none;
      width: 8rem;
      height: auto;
      max-height: 90vh;
      overflow-y: auto;
      border-radius: 0.2rem;
    }
  }

  .de-btn {
    width: 4.4rem;
    height: 0.55rem;
  }
}
</style>