<template>
  <div class='pg-tabs'>
    <div class="arrow left" v-show="scrollLeft > 0" @click="boxScroll()">
      <Icon type='arrow-right-line' />
    </div>
    <div class="tab-scroll-content" ref="scrollWrap" @scroll="scroll">
      <div class="scroll-body" ref="scrollBody">
        <div class="tab-item" v-for="(item, index) in list" :key="index" :class="{ active: currentTab === index }"
          @click="handleClick(index)">
          <!-- <img class="tab-img" :src="currentTab === index ? item.icon_after : item.icon_before" alt=""> -->
          <div class="icon-wrap">
            <img v-if="currentTab === index" :src="icons[item.tag_code]" alt="">
            <Icon v-else :type="item.tag_code"></Icon>
          </div>
          <div class="tab-text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="arrow right" v-show="scrollLeft < maxScollLeft - 50" @click="boxScroll(true)">
      <Icon type='arrow-right-line' />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
export default {
  name: 'PgTabs',
  props: {
    list: {
      type: Array,
      default() {
        return [

        ]
      }
    }
  },
  data() {
    return {
      icons:{
        amigos:require('@/assets/images/category/amigos.png'),
        jogos:require('@/assets/images/category/jogos.png'),
        popular:require('@/assets/images/category/popular.png'),
        slots:require('@/assets/images/category/slots.png'),
        blockchain:require('@/assets/images/category/blockchain.png'),
        pescaria:require('@/assets/images/category/pescaria.png'),
        cartas:require('@/assets/images/category/cartas.png'),
        cassino:require('@/assets/images/category/cassino.png'),
        esporte:require('@/assets/images/category/esporte.png'),
        clube:require('@/assets/images/category/clube.png'),
        esport: require('@/assets/images/category/esport.png'),
        luta: require('@/assets/images/category/luta.png'),
        loteria: require('@/assets/images/category/loteria.png')
      },
      boxWidth: 0,
      scrollLeft: 0,
      maxScollLeft: 0,
      isScroll: false,
      scroll: _.debounce((e) => {
        this.$set(this, 'scrollLeft', e.srcElement.scrollLeft)
      }, 200)
    }
  },
  computed: {
    ...mapState({
      currentTab: state => state.home.currentTab
    })
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  activated() {
    this.$refs.scrollWrap.scrollLeft = this.scrollLeft
  },
  watch: {
    list: {
      handler(val) {
        if (val.length) {
          this.current = 0;
          this.initBoxInfo()
        }
      },
      immediate:true
    },
    currentTab: {
      handler(index) {
        let scrollBox = this.$refs.scrollWrap;
        let currentEl = this.$refs.scrollBody.children[index];
        let left = currentEl.offsetLeft - scrollBox.offsetLeft - this.boxWidth / 2 + currentEl.clientWidth / 2;
        this.scrollLeft = left;
        scrollBox.scrollTo({
          left,
          behavior: 'smooth'
        })
      }
    }
  },
  methods: {
    handleResize() {
      this.initBoxInfo()
    },
    initBoxInfo() {
      console.log("loaded");
      this.$nextTick(() => {
        this.boxWidth = this.$refs.scrollWrap.clientWidth
        this.maxScollLeft = this.$refs.scrollBody.clientWidth - this.$refs.scrollWrap.clientWidth
      })
    },
    boxScroll(right) {
      const scrollWrapWidth = this.$refs.scrollWrap.clientWidth
      const left = this.scrollLeft + (right ? scrollWrapWidth : -scrollWrapWidth)
      this.$refs.scrollWrap.scrollTo({
        left,
        behavior: 'smooth'
      })
    },
    handleClick(index) {
      // this.$emit('change')
      this.$store.commit('SET_CURRENT_TAB_ISSCROLL', false)
      this.$store.commit('SET_CURRENT_TAB', index)
    }
  },
  beforeDestroy() {
    try {
      window.removeEventListener('resize', this.handleResize);
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style lang='scss' scoped>
.pg-tabs {
  height: 0.94rem;
  border-bottom: 1px solid var(--theme-text-color);
  overflow: hidden;
  position: relative;

  .arrow {
    background-color: rgba(0, 0, 0, 0.2);
    border: 0.01rem solid rgba(255, 255, 255, 0.1);
    border-radius: 0.18rem;
    top: 0.11rem;
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 0.36rem;
    line-height: 0.36rem;
    margin: 0.18rem 0px;
    text-align: center;
    width: 0.36rem;
    position: absolute;
    display: inline-block;
    align-items: center;
    justify-content: center;
    font-size: .14rem;
    padding-top: 0.01rem;
    box-sizing: border-box;
    z-index: 9;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
      transform: rotate(-180deg);
    }
  }

  .tab-scroll-content {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    font-size: 0;
    white-space: nowrap;
    padding-bottom: 0.4rem;

    .scroll-body {
      width: fit-content;

      .tab-item {
        color: var(--theme-text-color-lighten);
        display: inline-flex;
        flex-direction: column;

        height: 0.94rem;
        min-width: 1.5rem;
        text-align: center;
        font-size: .22rem;
        position: relative;
        user-select: none;
        cursor: pointer;
        .icon-wrap{
          display: flex;
          height: .46rem;
          width: 100%;
          justify-content: center;
          align-items: flex-end;
          font-size: 0.36rem;
          img{
            width: 100%;
            height: 0.4rem;
            object-fit: contain;
          }
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0;
          background: var(--theme-primary-color);
          transition: height .3s;
          border-radius: .04rem .04rem 0 0;
        }

        &:hover {
          color: var(--theme-primary-color);
        }

        &.active {
          color: var(--theme-primary-color);

          &::after {
            height: .04rem;
          }
        }

        .tab-img {
          display: block;
          margin: 0.05rem auto 0;
          width: 0.64rem;
          height: 0.4rem;
          object-fit: contain;
        }

        .tab-text {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

  }
}

.pc-page {
  .pg-tabs {
    flex: 1;
    height: 0.8rem;

    .tab-scroll-content .scroll-body .tab-item {
      height: 0.8rem;
      min-width: 1rem;
      font-size: .18rem;
      max-width: 1.2rem;

      .tab-img {
        width: 0.4rem;
      }
    }
  }
}

.mobile-page{
  .tab-item {
    padding: 0 12px;
    .tab-text{
      width: 1.36rem;
      word-break: keep-all;
      white-space: normal;
    }
  }
}
</style>