import { render, staticRenderFns } from "./PgForm.vue?vue&type=template&id=474d348f&scoped=true"
import script from "./PgForm.vue?vue&type=script&lang=js"
export * from "./PgForm.vue?vue&type=script&lang=js"
import style0 from "./PgForm.vue?vue&type=style&index=0&id=474d348f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474d348f",
  null
  
)

export default component.exports